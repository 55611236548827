const Items = [
  {
    id: 1,
    mainImage: "/imges/1-1.png ",
    img: [
      "/imges/1-1.png ",
      "/imges/1-2.png ",
      "/imges/1-3.png ",
      "/imges/1-4.png ",
      "/imges/1-5.png ",
      "/imges/1-6.png ",
    ],
    features: [
      "Home ",
      "About",
      "Contact",
      "Shop",
      "Blog",
      "This Is the Frontend of the E-Commerce Website. It is Fully Responsive and Optimized Modren Design",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "TailwindCSS",
      "React Hooks",
      "React icons",
      "Email JS",
    ],
    title: "Frontend E-Commerce Website",
    description:
      "This Is the Frontend of the E-Commerce Website. It is Fully Responsive and Optimized Modren Design",
    category: "business",
    liveLink: "https://react-ecommerce-web-site.netlify.app/",
    codeLink: "https://github.com/Arbab-Mustafa/E-commerce_Reactjs",
  },
  {
    id: 2,
    mainImage: "/imges/2-1.png",
    img: [
      "/imges/2-1.png",
      "/imges/2-2.png",
      "/imges/2-3.png",
      "/imges/2-4.png",
      "/imges/2-5.png",
    ],
    features: [
      "User registration and login",
      "Account Creation",
      "Upload Content (Pics)",
      "CRUD operations (Story create, read, update and delete)",
      "Upload user ımages and story ımages to the server",
      "Liking stories and adding stories to the Reading list",
      "Commenting on the story",
      "Infinite Scroll",
      "Responsive Design",
      "404 Page and many more.",
    ],
    technologies: [
      "React",
      "TypeScript",
      "Appwrite",
      "React Hooks",
      "React Icons",
      "Tailwind CSS",
    ],
    title: "Snapgram",
    description: "Fullstack  social media web app ",
    category: "personal",
    liveLink: "https://react-social-mediaapp.vercel.app/",
    codeLink: "https://github.com/Arbab-Mustafa/Social-media-App",
  },
  {
    id: 3,
    mainImage: "/imges/3-2.png",
    img: [
      "/imges/3-1.png",
      "/imges/3-2.png",
      "/imges/3-3.png",
      "/imges/3-4.png",
      "/imges/3-5.png",
      "/imges/3-6.png",
      "/imges/3-7.png",
    ],
    features: [
      "User registration and login",
      " Account Creation",
      "Upload Content (Pics)",
      "Password Update",
      "Search Live",
      "Booking System",
      "Hotel Cabin Booking Details",
      "Account Edit, Update and Delete",
      "DarkMode",

      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Router Dom",
      "React Query",
      "Styled Components",
      "React Redux",
      "React Toastify",
      "Supabase",
    ],
    title: "Hotel Managemt System",
    description:
      "Fullstack  Webapp for Hotel Management System. It is Fully Responsive and Optimized Modren Design , Experience the site using the following credentials   'Email--test123@gmail.com'  'password---12345678' ",
    category: "business",
    liveLink: "https://wild-oasisi-ffull-stack.vercel.app/",
    codeLink: "https://github.com/Arbab-Mustafa/Wild_Oasisi-ffullStack",
  },
  {
    id: 4,
    mainImage: "/imges/4-1.png",
    img: [
      "/imges/4-1.png",
      "/imges/4-2.png",
      "/imges/4-3.png",
      "/imges/4-4.png",
      "/imges/4-5.png",
      "/imges/4-6.png",
    ],
    features: [
      "E-commerce Website",
      "Frontend design only ",
      "Tailwind CSS",
      "React",
      "Responsive Design",
    ],
    technologies: [
      "React",
      "React Hooks",
      "React Icons",
      "React Vite",
      "Tailwind CSS",
    ],
    title: "Front-end E-commerce Website",
    description:
      "This Is the Frontend of the E-Commerce Website. It is Fully Responsive and Optimized Modren Design",
    category: "business",
    liveLink: "https://ecommercearbab.netlify.app/",
    codeLink: "https://github.com/Arbab-Mustafa/E-commerce_Reactjs",
  },
  {
    id: 5,
    mainImage: "/imges/5-1.png",
    img: ["/imges/5-1.png", "/imges/5-2.png", "/imges/5-3.png"],
    features: ["Simple Landing Page", "Responsive Design"],
    technologies: ["html", "css"],
    title: "Simple Landing Page",
    description:
      "This Is the Simple Landing Page. It is Fully Responsive and Optimized Modren Design",
    category: "business",
    liveLink: "https://itech123.netlify.app/",
    codeLink: "https://github.com/Arbab-Mustafa/E-commerce_Reactjs",
  },
];

export default Items;
